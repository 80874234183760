<script>
  export let data;

  import { goto } from '$app/navigation';

  import FaLinkedin from 'svelte-icons/fa/FaLinkedin.svelte';
  import FaDribbble from 'svelte-icons/fa/FaDribbble.svelte';
  import FaGithub from 'svelte-icons/fa/FaGithub.svelte';

  import LifeCalendar from '$lib/components/LifeCalendar.svelte';
  import SkillsList from '$lib/components/SkillsList.svelte';

  import '$lib/utils/polyfills';

  const links = [
    { id: 2, Icon: FaDribbble, title: 'Dribbble', url: 'https://dribbble.com/bkeating' },
    { id: 1, Icon: FaGithub, title: 'GitHub', url: 'https://github.com/bkeating/' },
    { id: 5, Icon: FaLinkedin, title: 'LinkedIn', url: 'https://www.linkedin.com/in/bkeating/' },
  ];

  const objective = `To build amazing things with wonderful people. To be the teacher and the student. To pay back my mentors by mentoring it forward. To listen so I can better understand. To enjoy how.`;

  const aboutMe = `Whether it be hardware or software, It's always been about user interfaces. I'm simply obsessed with dreaming them up, building them out, and whittling them down until one of two things happens; They become skeuomorphic or invisible. I'm not talking just the pixels and knobs, but everything behind them that is necessary to yield the very best experience. Within all of this is a deep-rooted passion in designing for scale and longevity. Interfaces you can rely on.<br /><br />When I'm not focused on user interfaces I'm spending time with my kids trying to be the best dad I can be, while taking in all the experience I possibly can to help connect people and make my time here count. The time sure is flying by...`;

  const caseStudy = `Showcased Works Reflecting My Journey in Full Stack UI/UX Engineering and Creative Problem Solving.`;

  const workHistory = [
    {
      title: 'Backend Engineer',
      subline: '02024 - present @ Octopus Energy',
      description: 'Building and designing tools to optimize the development and education of power utility software. Python, Django, AWS, Datadog'
    },
    {
      title: 'Director of UI/UX',
      subline: '02022 — Jan 02024 @ Landus, Inc.',
      description: 'Specifically sought out by the CTO to spearhead the development of innovative software solutions, significantly enhancing IT morale and collaboration. I led the design and implementation of a custom, agricultural-aware CRM system, revolutionizing customer management for some 50k customers across 60 locations and dramatically improving customer satisfaction.',
    },
    {
      title: 'Senior Software Engineer / UX Engineer',
      subline: '02019 — 02022 @ Northwestern Mutual',
      description: ' Secured AWS Cloud certification and enhanced the design system, leading market research that expanded our virtual athletic competition SaaS client base by 25%. My work in developing innovative solutions and advocating for Svelte and reactive programming significantly boosted project success and efficiency.'
    },
    {
      title: 'Full-stack Developer',
      subline: '02016 — 02018 @ Nature’s Classroom Institute',
      description: '',
      newBranchStart: true,
      newBranchEnd: true,
    },
    {
      title: 'Designer & Developer / Product Marketing Engineer',
      subline: '02015 — 02019 @ RP Nutrients, Inc.',
      description: 'Engineered cutting-edge Laboratory Information Management and dairy farm automation software written in Django, adopted by major industry corporations; led development using React, GraphQL, and JavaScript; Orchestrated highly effective marketing strategies which resulted in expo bylaws needing to be rewritten.'
    },
    {
      title: 'President, Board Member',
      subline: '02014 — Present @ East Troy Area Chamber of Commerce',
      description: '',
      newBranchStart: true
    },
    {
      title: 'Personal IT/Sysadmin Assistant',
      subline: '02007 — 02013 for Kevin Kelly',
      description: '',
    },
    {
      title: 'UNIX System Administrator',
      subline: '02004 — 02005 @ Applied Minds, Inc.',
      description: '',
      newBranchEnd: true
    },
    {
      title: 'Sysadmin, Designer & Developer',
      subline: '02003 — 02015 @ The Long Now Foundation',
      description: 'My contributions were pivotal in establishing and enhancing design systems for brand continuity, effectively branding initiatives like <a href="https://reviverestore.org/events/tedxdeextinction/" class="text-blue-500 underline" target="_blank">TEDxDeExtinction</a> and creating logos for key projects such as <a href="https://theinterval.org/" class="text-blue-500 underline" target="_blank">The Interval</a> and the <a href="https://rosettaproject.org/" class="text-blue-500 underline" target="_blank">Rosetta Project</a>. I helped launch and growth of the Membership program, expanding our community to over 12,000 members. I supported the inclusion of the Rosetta Project in the <a href="https://www.esa.int/Science_Exploration/Space_Science/Rosetta" class="text-blue-500 underline" target="_blank">ESA comet mission</a>, highlighting our commitment to preserving human languages and promoting linguistic diversity, and led the digital overhaul of our primary websites using Django, jQuery, and MySQL, ensuring seamless migration from legacy systems. My role also encompassed managing comprehensive A/V needs for events, closely collaborating with venue staff on coordination, and executing a full migration of our server infrastructure to AWS, significantly boosting our operational efficiency and future scalability.'
    },
    {
      title: 'Designer, Programmer, Sysadmin',
      subline: '02000 — 02003 @ Vector Industries, Inc.',
      description: 'My career as a knowledge worker started here, when I was lucky enough to convince the boss to move me from the factory floor to the front office to help design our product brochures and establish a web presence. This eventually led to working with an investor and taking on the challenge of creating an inventory and invoicing system written in PHP & MySQL on a LAMP stack.',
    }
  ];

  let theOneBefore = null;
  let y;
</script>

<!-- <svelte:window bind:scrollY={y} /> -->

<div class="w-full md:px-6 max-w-[1420px] mx-auto md:mt-9 transition-all">
  <img src="/assets/splash.jpg" alt="" class="w-full md:rounded-3xl md:shadow-2xl" />
</div>

<main class="max-w-[1024px] mx-auto px-3 md:px-6 mb-24">

  <div class="flex flex-wrap md:mt-16 md:mb-16 -mt-6">
    <div class="flex flex-grow flex-wrap justify-center">
      <div class="md:flex md:flex-grow items-center gap-2 md:gap-4 text-center md:text-left">
        <div>
          <img src="https://benjaminkeating.com/assets/avatar.jpg" alt="Headshot" class="mx-auto md:mx-none w-24 h-24 rounded-full border-4 border-[#fff] shadow-xl" />
        </div>
        <div class="flex flex-col">
          <span class="text-sm mt-3 md:mt-auto font-bold text-[#865e16] ">Professionally Creative Knowledge Worker</span>
          <h1 class="font-bold text-4xl md:text-6xl">Benjamin Keating</h1>
        </div>
      </div>
      <div class="w-full md:w-auto flex flex-col flex-grow-0 justify-center mt-4 text-center md:text-right gap-1.5">
        <div class="flex justify-center md:justify-end gap-3">
          {#each links as {Icon, title, url}}
            <a href={url} {title} target="_blank" rel="noreferrer noopener" class="flex items-center text-[#315475]">
              <div class="inline-block w-7 h-7 md:h-5 md:w-7 mb-1">
                <Icon />
              </div>
            </a>
          {/each}
        </div>
        <p><a href="mailto:bkeating@gmail.com" class="text-[#865e16] font-semibold">bkeating@gmail.com</a></p>
      </div>
    </div>
  </div>

  <div class="flex mt-9 flex-wrap justify-between">
    <div class="w-full md:w-1/2 px-3 md:px-0">

      <div class="visible md:hidden mb-9">
        <h2 class="text-3xl md:text-4xl font-semibold mb-3">About Me</h2>
        <p>{@html aboutMe}</p>
      </div>

      <h2 class="text-3xl md:text-4xl font-semibold mb-3 text-pretty">Full Stack Developer / UI Engineer</h2>
      <p>{@html objective}</p>

      <div class="visible md:hidden mt-9">

        <h2 class="text-3xl md:text-4xl font-semibold mb-3">Case Studies</h2>
      <p class="">{caseStudy}</p>

      <div class="py-3 mb-9">
        <div class="flex gap-3 mt-3 flex-nowrap ">
          <div on:keyup={() => goto('/milker')} on:click={() => goto('/milker')} role="button" tabindex="-1" class="cursor-pointer relative w-1/2 h-[180px] bg-gray-800 rounded-lg shadow border bg-[url(/assets/milker/tn-milker-screenshot.jpg)] bg-cover bg-top">
            <img src="/assets/milker/logo.png" alt="Milker" class="absolute w-[100px] bottom-[-10px] right-[-5px]" />
          </div>
          <div on:keyup={() => goto('/nci')} on:click={() => goto('/nci')} tabindex="-1" class="cursor-pointer relative w-1/2 h-[180px] bg-gray-800 rounded-lg shadow border bg-[url(/assets/nciw/tn-discovernci.jpg)] bg-cover bg-top">
            <img src="/assets/nciw/logo.png" alt="Milker" class="absolute w-[64px] bottom-[-10px] right-[-5px]" />
          </div>
        </div>
      </div>


      <h2 class="text-3xl md:text-4xl font-semibold sticky top-0 bg-white">Skills</h2>

      <div class="flex items-center gap-3 mt-3 pb-3 sticky top-[34px] bg-white">
        <h3 class="font-semibold -ml-3 pl-3">Design</h3>
        <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
      </div>
      <ul class="list-disc ml-4">
        <li>20+ years in UI/UX, graphic design</li>
        <li>Power user of Ai, Id, Ps, Xd, Figma, Sketch</li>
        <li>Master of mock-ups, wire-frames and napkin sketches</li>
        <li>Fluent in motion design and micro-interactions</li>
        <li>Rapid iteration champion; Designs-in-code</li>
        <li>A seasoned veteran of responsive design</li>
        <li>Proficient in working on design systems</li>
        <li class="italic">“An Engineer’s Designer”</li>
      </ul>

      <div class="flex items-center gap-3 mt-4 pb-3 sticky top-[34px] bg-white">
        <h3 class="font-semibold -ml-3 pl-3">Develop</h3>
        <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
      </div>
      <ul class="list-disc ml-4">
        <li>20+ years in PHP, Python, HTML, CSS, JavaScript</li>
        <li>Exceptional in Django, Node, React, Svelte, Tailwind</li>
        <li>Pixel-perfect CSS (can match mock-ups perfectly)</li>
        <li>Solid Relational and NoSQL database experience</li>
        <li>Relentless Automator but doesn’t over-abstract</li>
        <li>Versatile in PWA and SPA development stacks</li>
        <li>Solid foundation in Git and its advanced uses</li>
        <li>An ardent supporter of reactive programming</li>
        <li>Skilled in building REST and GraphQL APIs</li>
        <li>Responsible for longevity and maintenance</li>
      </ul>

      <div class="flex items-center gap-3 mt-4 mb-3 sticky top-[34px] bg-white">
        <h3 class="font-semibold -ml-3 pl-3">Systems</h3>
        <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
      </div>
      <ul class="list-disc ml-4">
        <li>20+ years in UNIX system administration</li>
        <li>Ubuntu, Gentoo (Stage 0), FreeBSD, OpenBSD</li>
        <li>Graffana, Splunk, Nagios, New Relic, CloudWatch</li>
        <li>Competent in Docker, Kubernetes, Terraform, LXC</li>
        <li>Shell scripting power user (Bash, Python, Perl)</li>
        <li>Experienced in hardware forensics and data recovery</li>
        <li>Effective DNS experience using BIND, djbdns, squid</li>
        <li>Administrator of Sendgrid, Sendmail, qmail, postfix</li>
        <li>Circuit soldering and hardware troubleshooting</li>
        <li>Shell scripting power user (Bash, zsh)</li>
      </ul>

    </div>

    <div class="mt-9">
      <h2 class="text-3xl md:text-4xl font-semibold mb-3 sticky top-0 bg-white z-10 pl-3 -ml-3 pb-2">Work History</h2>
      <div class="border-l-4 border-[#dec398] flex flex-col pl-4">
        {#each workHistory as job}
          {@const theOneBefore = !!job.description}
          <div class="relative job-entry {job.description ? 'my-3' : `${job.newBranchStart ? 'mt-5' : ''} ${job.newBranchEnd ? 'mb-5' : ''} border-l-4 pl-3 border-[#dec398]`}">
            {#if job.newBranchStart}
            <div class="-rotate-45 flex flex-col w-[4px] absolute top-[-24px] left-[-14px]">
              <div class="h-[30px] w-1 bg-[#dec398]" />
            </div>
            {/if}
            {#if job.description}
              <div class="h-3 w-3 bg-[#865e16] rounded-full absolute top-[9px] -ml-[24px]" />
            {:else}
              <div class="absolute left-[-8px] top-[9px] w-3 h-3 bg-white border-[3px] border-[#865e16] rounded-full" />
            {/if}
            <h4>{job.title}</h4>
            <div class="italic  text-[#a29683] {!job.description && !job.newBranchEnd ? 'mb-4' : ''}">{job.subline}</div>
            <p class="job-description">{@html job.description}</p>
            {#if job.newBranchEnd}
            <div class="rotate-45 flex flex-col w-[4px] absolute bottom-[-24px] left-[-14px]">
              <div class="h-[30px] w-1 bg-[#dec398]" />
            </div>
            {/if}
          </div>
        {/each}
      </div>

      <div class="md:block hidden">
        <h2 class="text-3xl md:text-4xl font-semibold mt-9 mb-3">Life Calendar</h2>
        <p class="mb-6">
          Each square amounts to a week. Each row, a year. The red dot is <span class="italic">now</span> and the total sum represents 82 years of life. Slower is faster. Less is more. Enjoy it.
        </p>
        <LifeCalendar timelineData={data.timelineData} />
      </div>
    </div>
    </div>
    <div class="w-full md:w-1/2 px-3 md:pl-9 md:pr-0 mt-9 md:mt-0">

      <div class="md:block hidden">
        <h2 class="text-3xl md:text-4xl font-semibold mb-3">About Me</h2>
        <p>{@html aboutMe}</p>
      </div>
    <div class="md:block hidden mt-9">

      <h2 class="text-3xl md:text-4xl font-semibold mb-3">Case Studies</h2>
      <p>{caseStudy}</p>

      <div class="py-3">
        <div class="flex gap-3 mt-3 flex-nowrap ">
          <div on:keyup={() => goto('/milker')} on:click={() => goto('/milker')} role="button" tabindex="-1" class="cursor-pointer relative w-1/2 h-[180px] bg-gray-800 rounded-lg shadow border bg-[url(/assets/milker/tn-milker-screenshot.jpg)] bg-cover bg-top">
            <img src="/assets/milker/logo.png" alt="Milker" class="absolute w-[100px] bottom-[-10px] right-[-5px]" />
          </div>
          <div on:keyup={() => goto('/nci')} on:click={() => goto('/nci')} tabindex="-1" class="cursor-pointer relative w-1/2 h-[180px] bg-gray-800 rounded-lg shadow border bg-[url(/assets/nciw/tn-discovernci.jpg)] bg-cover bg-top">
            <img src="/assets/nciw/logo.png" alt="Milker" class="absolute w-[64px] bottom-[-10px] right-[-5px]" />
          </div>
        </div>
      </div>

      <SkillsList />

    </div>

    <div class="md:mt-9">
      <h2 class="text-3xl md:text-4xl font-semibold mb-3">Volunteer Work</h2>

      <a href="https://www.meetup.com/East-Troy-Computer-Club/" target="_blank">
        <img src="/assets/etcc.jpg" alt="East Troy Computer Club" class="w-full mt-6 rounded-lg shadow-lg" />
      </a>

      <h4 class="mt-6 font-semibold">Founder, East Troy Computer Club</h4>
      <ul class="list-disc mt-2 mb-6 ml-4">
        <li>Presented on 70+ original topics. </li>
        <li>Created and grew the club to 400+ Members. </li>
        <li>Designed logos, and signage for community / local govt. </li>
        <li>Led transparency projects, workshops, and mentorship.</li>
        <li>Enhanced digital literacy and tech skills with educators.</li>
        <li>Featured in PBS’s Around the Corner with John McGivern (<a href="https://video.pbswisconsin.org/video/around-corner-john-mcgivern-east-troy-413/" class="text-blue-500 underline" target="_blank">S04E13</a>).</li>

      </ul>

      <a href="https://www.easttroylights.com/" target="_blank">
        <img src="/assets/et-lights.jpg" alt="East Troy Computer Club" class="w-full mt-3 mb-6 rounded-lg shadow-lg" />
      </a>

      <h4 class=" font-semibold">Imagineer, East Troy Lights</h4>
      <ul class="list-disc mt-2 mb-6 ml-4">
        <li>Built initial PoC using Python on a Raspberry Pi.</li>
        <li>Key contributor in development and creative strategy.</li>
        <li>As seen in Midwest Living, Milwaukee Journal Sentinel, and Milwaukee Magazine.</li>
      </ul>
    </div>

    <div class="md:hidden">

        <h2 class="text-3xl md:text-4xl font-semibold mt-9 mb-3">Life Calendar</h2>
        <p class="mb-6">
          Each square amounts to a week. Each row, a year. The red dot is <span class="italic">now</span> and the total sum represents 82 years of life. Slower is faster. Less is more. Enjoy it.
        </p>
        <LifeCalendar timelineData={data.timelineData} />
      </div>

    </div>
  </div>
</main>

<style>
  h1, h2 {
    font-family: 'EB Garamond', serif;
    @apply text-[#315475];
  }

  h3 {
    @apply text-xl text-[#865e16];
  }
  h4 {
    @apply text-lg text-[#315475] font-bold;
  }
  ul, p {
  font-family: 'Fira Sans', sans-serif;
    @apply text-[#374453];
  }
  .job-entry {
    @apply relative;
  }
  .job-description {
    @apply border-l-4 border-[#b0b9c3] pl-3 text-sm mt-2 mb-1 text-[#4b5b6c];
  }
  .job-description:empty {
    @apply hidden;
  }
</style>
